import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeFive from '../views/HomeFive.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomeFive',
        component: HomeFive
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // stop stop cache
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})

export default router
