<template>
  <div>
    <header-home-five v-if="localData" :data="localData"/>
    <hero-home-five v-if="localData" :data="localData.main_slider" :links="localData.general"/>
    <services-home-three v-if="localData" :data="localData.second_section"/>
    <feature-home-two v-if="localData" :data="localData.third_section"/>
    <download-home-three v-if="localData" class="pt-90 pb-90" :data="localData.general"/>
    <show-case-home-three v-if="localData" :data="localData.creative_app_showcases"/>
    <testimonial-home-one v-if="localData" :data="localData.testimonials"/>
    <footer-home-one v-if="localData" :data="localData"/>
    <div class="back-to-top">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
  </div>
</template>

<script>
import FooterHomeOne from "../HomeOne/FooterHomeOne.vue";
import TestimonialHomeOne from "../HomeOne/TestimonialHomeOne.vue";
import DownloadHomeThree from "../HomeThree/DownloadHomeThree.vue";
import ServicesHomeThree from "../HomeThree/ServicesHomeThree.vue";
import ShowCaseHomeThree from "../HomeThree/ShowCaseHomeThree.vue";
import FeatureHomeTwo from "../HomeTwo/FeatureHomeTwo.vue";
import HeaderHomeFive from "./HeaderHomeFive.vue";
import HeroHomeFive from "./HeroHomeFive.vue";

export default {
  components: {
    HeaderHomeFive,
    HeroHomeFive,
    ServicesHomeThree,
    FeatureHomeTwo,
    DownloadHomeThree,
    ShowCaseHomeThree,
    TestimonialHomeOne,
    FooterHomeOne,
  },
  data() {
    return {
      sidebar: false,
      localData: null,
    };
  },
  async mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  async beforeMount() {
    document.addEventListener("scroll", this.topToBottom);
    await this.$store.dispatch('fetchData');
    this.localData = this.$store.getters.getData;
    console.log('Data fetched and saved locally:', this.localData.seo);

  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>