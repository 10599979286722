<template>

  <!--====== APPIE HERO PART START ======-->

  <section class="appie-hero-area appie-hero-4-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="appie-hero-content appie-hero-content-4">
            <h1 class="appie-title">{{ this.data.title }}</h1>
            <p>{{
                this.data.description
              }}</p>
            <ul>
              <li><a :href="this.links.android_url " class="py-2 px-5"><i class="fab fa-google-play"></i> <span>Google Play</span></a>
              </li>
              <li><a class="item-2 py-2 px-5" :href="links.ios_url"><i class="fab fa-apple"></i> <span>App Store</span></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="appie-hero-thumb appie-hero-thumb-4">
            <img :src="this.data.image" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="hero-shape-1">
      <img src="@/assets/images/shape/shape-10.png" alt="">
    </div>
  </section>

  <!--====== APPIE HERO PART ENDS ======-->

</template>

<script>
export default {
  props: ['data', 'links'],
  // data() {
  //   return {
  //     localData: null
  //   }
  // },
  mounted() {
    console.log(this.data)
  },
  async beforeMount() {
    // this.localData = this.$store.getters.getData;
    // console.log('Data fetched and saved locally:', this.localData);
  },
}
</script>

<style>

</style>