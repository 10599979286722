import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        data: null,
        termsAndConditions: null,
        privacyPolicy: null,
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        },
        setTermsAndConditions(state, data) {
            state.termsAndConditions = data;
        },
        setPrivacyPolicy(state, data) {
            state.privacyPolicy = data;
        },
    },
    getters: {
        getData(state) {
            return state.data;
        },
        getTermsAndConditions(state) {
            return state.termsAndConditions;
        },
        getPrivacyPolicy(state) {
            return state.privacyPolicy;
        }
    },
    actions: {
        async fetchData({commit}) {
            await axios.get('https://app.uslinq.com/api/front-settings')
                .then(response => {
                    commit('setData', response.data.result);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        async fetchTermsAndConditions({commit}) {
            await axios.get('https://app.uslinq.com/api/system-settings/terms-conditions')
                .then(response => {
                    commit('setTermsAndConditions', response.data.result);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        async fetchPrivacyPolicy({commit}) {
            await axios.get('https://app.uslinq.com/api/system-settings/privacy-policy')
                .then(response => {
                    commit('setPrivacyPolicy', response.data.result);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
    },
});
