<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"><img :src="this.data.seo.logo" alt=""/></a>
            </div>
            <p>
              {{ this.data.general.short_about }}
            </p>
            <div class="social mt-30">
              <ul>
                <li>
                  <a :href="this.data.social_media.facebook"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a :href="this.data.social_media.twitter"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a :href="this.data.social_media.linkedin"><i class="fab fa-linkedin-in"></i></a>
                </li>
                <li>
                  <a :href="this.data.social_media.instagram"><i class="fab fa-instagram"></i></a>
                </li>
                <li>
                  <a :href="this.data.social_media.youtube"><i class="fab fa-youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Support</h4>
            <ul>
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/terms-conditions">Terms and conditions</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Get In Touch</h4>
            <ul>
              <li>
                <a href="#"
                ><i class="fal fa-envelope"></i> {{ this.data.contact.email }}</a
                >
              </li>
              <li>
                <a href="#"><i class="fal fa-phone"></i> {{ this.data.contact.mobile }}</a>
              </li>
              <li>
                <a href="#"
                ><i class="fal fa-map-marker-alt"></i> {{ this.data.contact.address }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
              class="
              footer-copyright
              d-flex
              align-items-center
              justify-content-between
              pt-35
            "
          >
            <div class="apps-download-btn">
              <ul>
                <li>
                  <a :href="this.data.general.ios_url"><i class="fab fa-apple"></i> Download for iOS</a>
                </li>
                <li>
                  <a class="item-2" :href="this.data.general.android_url"
                  ><i class="fab fa-google-play"></i> Download for Android</a
                  >
                </li>
              </ul>
            </div>
            <div class="copyright-text">
              <p>Copyright © 2024 Us Linq .Powered by <a href="https://vegasds.com" target="_blank"> Vega SDS</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {
  props: ['data']
};
</script>

<style>
</style>