<template>
  
      <!--====== APPIE SERVICES PART START ======-->
    
    <section class="appie-service-area appie-service-3-area pt-195 pb-100" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">{{ this.data.title }}</h3>
                        <p>{{this.data.description}} </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="icon">
                            <img :src="this.data.icon_one" alt="">
                        </div>
                        <h4 class="appie-title">{{ this.data.title_one }}</h4>
                        <p>{{ this.data.description_one }}</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="icon">
                            <img :src="this.data.icon_two" alt="">
                        </div>
                        <h4 class="appie-title">{{this.data.title_two}}</h4>
                        <p>{{this.data.description_two}}</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="icon">
                            <img :src="this.data.icon_three" alt="">
                        </div>
                        <h4 class="appie-title">{{this.data.title_three}}</h4>
                        <p>{{ this.data.description_three}}</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                        <div class="icon">
                            <img :src="this.data.icon_four" alt="">
                        </div>
                        <h4 class="appie-title">{{ this.data.title_four}}</h4>
                        <p>{{ this.data.description_four}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {

  props:['data']

}
</script>

<style>

</style>