<template>
  <div>
    <header-home-five v-if="localData" :data="localData"/>
    <section class="appie-hero-area appie-hero-4-area">
      <div v-if="this.data" v-html="data.privacy_policy"></div>
    </section>
    <footer-home-one v-if="localData" :data="localData"/>
  </div>
</template>

<script>
import HeaderHomeFive from "../components/HomeFive/HeaderHomeFive.vue";
import FooterHomeOne from "../components/HomeOne/FooterHomeOne.vue";

export default {
  components: {FooterHomeOne, HeaderHomeFive},
  data() {
    return {
      data: null,
      localData: null
    };
  },
  async beforeMount() {
    await this.$store.dispatch('fetchData');
    this.localData = this.$store.getters.getData;
    await this.$store.dispatch('fetchPrivacyPolicy');
    this.data = this.$store.getters.getPrivacyPolicy;
  },
}
</script>

<style>

</style>