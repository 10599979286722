<template>
  <!--====== APPIE FEATURES 2 PART START ======-->

  <section class="appie-features-area-2 pt-90 pb-100" id="features">
    <div class="container">
<!--      <div class="row justify-content-center">-->
<!--        <div class="col-lg-12">-->
<!--          <div class="appie-section-title appie-section-title-2 text-center">-->
<!--            <h3 class="appie-title">{{ this.data.title }}</h3>-->
<!--            <p>{{ description }}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row mt-30 align-items-center">
        <div class="col-lg-6">
          <div class="appie-features-boxes">
            <div class="appie-features-box-item">
              <h4 class="title">{{this.data.title_one}}</h4>
              <p>{{this.data.description_one}}</p>
            </div>
            <div class="appie-features-box-item item-2">
              <h4 class="title">{{this.data.title_two}}</h4>
              <p>{{this.data.description_two}}</p>
            </div>
            <div class="appie-features-box-item item-3">
              <h4 class="title">{{this.data.title_three}}</h4>
              <p>{{this.data.description_three}}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appie-features-thumb wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms">
            <img :src="this.data.image" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="features-shape-1">
      <img src="@/assets/images/shape/shape-15.png" alt="">
    </div>
    <div class="features-shape-2">
      <img src="@/assets/images/shape/shape-14.png" alt="">
    </div>
    <div class="features-shape-3">
      <img src="@/assets/images/shape/shape-13.png" alt="">
    </div>
  </section>

  <!--====== APPIE FEATURES 2 PART ENDS ======-->
</template>

<script>
export default {
  props: ['data']

}
</script>

<style>

</style>