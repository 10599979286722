<template>
  <header class="appie-header-area appie-sticky">
    <div class="container">
      <div class="header-nav-box header-nav-4-box">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
            <div class="appie-logo-box">
              <a href="/">
                <img :src="this.data.seo.logo" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ['data'],
  components: { },
  mounted() {
    document.addEventListener("scroll", this.stickMenu);
  },
  methods: {
    stickMenu() {
      const result = document.querySelector(".appie-sticky");
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        result.classList.add("sticky");
      } else {
        result.classList.remove("sticky");
      }
    },
  },
};
</script>

<style>
</style>